html, body, #root {
  height: 100%;
}

body {
  overflow: hidden; /* Hide scrollbars */
}

.vh-100 {
  min-height: 100vh;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.zow-primary {
  background-image: url("../public/zow-colors.svg");
}

.side-panel {
  background-color: #1e2e4a;
  border: 10px solid #1f3559;
  color: #bcc1c8;
}

.main-panel {
  padding: 0px !important;
  border-top: 10px solid #1f3559;
}

.hoverlink {
  text-decoration: none;
  color: white;
}

.focus {
  color: #00ccbe;
}

.hoverdiv {
  position: absolute;
  background-color: #ffffff;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid #1f3559;
}

.alarmHeading {
  font-size: 25px;
}

.tableSpecifier{
  color: #202020;
}


.tableValue{
  color: #007067;
}